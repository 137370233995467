// extracted by mini-css-extract-plugin
export var __wab_imgSpacer = "PlasmicResourcesTextBox-module--__wab_img-spacer--rmzgh";
export var freeBox__eLoO = "PlasmicResourcesTextBox-module--freeBox__eLoO--4pxjX";
export var freeBox__l4Qft = "PlasmicResourcesTextBox-module--freeBox__l4Qft--Skb8K";
export var freeBox__mgoys = "PlasmicResourcesTextBox-module--freeBox__mgoys--LccMo";
export var freeBox__nl61I = "PlasmicResourcesTextBox-module--freeBox__nl61I--dMJf7";
export var freeBox__o1LZb = "PlasmicResourcesTextBox-module--freeBox__o1LZb--FOyw-";
export var freeBox__uVorp = "PlasmicResourcesTextBox-module--freeBox__uVorp--gnGRd";
export var freeBox__xCaA0 = "PlasmicResourcesTextBox-module--freeBox__xCaA0--02yVq";
export var img = "PlasmicResourcesTextBox-module--img--L4UY6";
export var root = "PlasmicResourcesTextBox-module--root--jrq+1";
export var slotTargetCategory = "PlasmicResourcesTextBox-module--slotTargetCategory--96PIz";
export var slotTargetTextIn = "PlasmicResourcesTextBox-module--slotTargetTextIn---94I+";