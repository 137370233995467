// extracted by mini-css-extract-plugin
export var column___67R2A = "PlasmicIdeas-module--column___67R2A--1NBBR";
export var column__faL7T = "PlasmicIdeas-module--column__faL7T--4Df8+";
export var column__qwHl6 = "PlasmicIdeas-module--column__qwHl6--gV79P";
export var column__xwF6E = "PlasmicIdeas-module--column__xwF6E--UQqcY";
export var footer = "PlasmicIdeas-module--footer--PQaWp";
export var freeBox__fgCpm = "PlasmicIdeas-module--freeBox__fgCpm--IO29b";
export var freeBox__iVu0H = "PlasmicIdeas-module--freeBox__iVu0H--ZFjW0";
export var freeBox__kEGx = "PlasmicIdeas-module--freeBox__kEGx--RPmwn";
export var h1__k9QX9 = "PlasmicIdeas-module--h1__k9QX9--cNfxk";
export var h1__umCBc = "PlasmicIdeas-module--h1__umCBc--03TaB";
export var navBar = "PlasmicIdeas-module--navBar--0jnWc";
export var postList = "PlasmicIdeas-module--postList--IOr+3";
export var resourcesTextBox = "PlasmicIdeas-module--resourcesTextBox--djmza";
export var root = "PlasmicIdeas-module--root--mzToc";
export var text = "PlasmicIdeas-module--text--aTbeQ";